<template>
  <!--Header Component-->
  <HeaderComponent />
  <!--Footer Component -->
  <v-container>
    <v-sheet class="text-center mt-5" style="font-size: 24px">
      <h1 class="text-h4 text-center text-brightBlue mt-10 mb-6">
        About Social Forge
      </h1>
      <p class="pl-md-10 pr-md-10 ml-md-10 mr-md-10 mb-6">
        Social Forge is the trusted source to assemble your remote development
        teams! We are a fully remote company with expert engineers and
        developers working tirelessly to help you upscale your business or
        complete that next project in a timely & effortless manor!
      </p>
      <v-sheet class="mb-10">
        <v-img :src="bannerImage" alt="about us banner image"></v-img>
      </v-sheet>
      <p class="pl-md-10 pr-md-10 ml-md-10 mr-md-10 mb-6">
        We help you position your business for success in the ever-growing tech
        landscape whether you are looking to explore your companies limitless
        possibilities or just need to finish that next task, we have you
        covered!
      </p>
      <p class="pl-md-10 pr-md-10 ml-md-10 mr-md-10 mb-6">
        Our growing library of solutions has helped diversify the range of
        services at our disposal and bring more value to customers. As a result,
        we chose to fully embrace the digital mindset in all operations, from
        integrating automated processes that operate seamlessly to designing &
        building customer-centric technology.
      </p>
      <p class="pl-md-10 pr-md-10 ml-md-10 mr-md-10 mb-6">
        At Social Forge we prioritize a client-centric approach, which means we
        work closely with our clients to gain a comprehensive understanding of
        their unique business requirements to provide tailor-made solutions
        aimed at maximizing their business potential.
      </p>
      <h2 class="text-h5 text-brightBlue">Who We Are</h2>
      <p class="pl-md-10 pr-md-10 ml-md-10 mr-md-10 mb-6">
        Social Forge is a leading software development company delivering
        tailor-made digital solutions to businesses worldwide. Our team of
        developers, designers and innovators can help create solutions across
        all industry verticals. As a close-knit team of experts, designers and
        full-stack developers, we undertake collaborative research and
        development to create next-gen applications and solutions perfectly
        suited to the evolving tech space.
      </p>
      <p class="pl-md-10 pr-md-10 ml-md-10 mr-md-10 mb-6">
        Our motto, ‘You Dream It: We Build It,’ reflects our business
        philosophy. From putting flesh on your idea to delivering the expected
        end product, we do whatever it takes to make your project successful.
      </p>
      <v-sheet max-width="600" class="mx-auto mb-10">
        <v-img :src="aboutImg" alt="about us banner image"></v-img>
      </v-sheet>
    </v-sheet>
  </v-container>
  <FooterComponent />
</template>
<script>
import { defineComponent } from "vue";
// Components
import HeaderComponent from "../components/layout/HeaderComponent.vue";
import FooterComponent from "../components/layout/FooterComponent.vue";

export default defineComponent({
  name: "AboutUs",
  components: {
    HeaderComponent,
    FooterComponent,
  },

  data: () => ({
    bannerImage: require("../assets/images/about/aboutus1.jpg"),
    aboutImg: require("../assets/images/about/aboutus2.jpg"),
  }),

  methods: {
    async getData() {
      // errors.value = {};
      try {
        // const result = await api.home.getData({ });
        // add data to lists and render
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
  },

  computed: {},
});
</script>
