import { createStore } from "vuex";
import { api } from "../services";
import type { User } from "@/services/requests";
import Storage from "../util/storage";

export const userStorage = new Storage<User>("user");

export const isAuthorized = (): boolean => !!userStorage.get();

export default createStore({
  state: {
    user: <User>{},
  },
  getters: {
    user(state) {
      return state.user;
    },
    isAuthorized(state) {
      return state.user !== null;
    },
  },
  mutations: {
    updateUser(state, userData?: User | null) {
      if (userData === undefined || userData === null) {
        userStorage.remove();
        api.setSecurityData(null);
        state.user = <User>{};
      } else {
        userStorage.set(userData);
        api.setSecurityData(userData.token);
        state.user = userData;
      }
    },
  },
  actions: {},
  modules: {},
});
