<template>
  <header id="header">
    <v-sheet color="primary">
      <v-container class="d-flex justify-space-between align-center">
        <h1 class="text-uppercase"><a href="./">Social Forge</a></h1>
        <img
          width="40"
          height="40"
          class="d-inline-flex"
          :src="sfLogo"
          alt="sf logo"
          @click.stop="drawer = !drawer"
        />
      </v-container>
    </v-sheet>
    <v-sheet class="header-menu d-none d-md-block">
      <v-container>
        <nav>
          <ul>
            <li><router-link to="./" exact>Home</router-link></li>
            <li>
              <router-link to="/SocialMedia" exact>
                Social Media Management
              </router-link>
            </li>
            <li>
              <router-link to="/Development" exact>Development</router-link>
            </li>
            <li><router-link to="/Pricing" exact>Pricing</router-link></li>
            <li><router-link to="/Resources" exact>Resources</router-link></li>
            <li><router-link to="/About" exact>About</router-link></li>
            <li>
              <router-link to="/ContactUs" exact> Contact Us </router-link>
            </li>
          </ul>
        </nav>
      </v-container>
    </v-sheet>
  </header>
  <v-navigation-drawer v-model="drawer" temporary>
    <v-sheet color="primary" class="pa-4 d-flex align-center">
      <img
        width="40"
        height="40"
        :src="sfLogo"
        alt="sf logo"
        @click.stop="drawer = !drawer"
      />
      <span class="text-white ml-2 text-uppercase">Social Forge</span>
    </v-sheet>
    <nav class="mobile-menu">
      <ul>
        <li><router-link to="./" exact>Home</router-link></li>
        <li>
          <router-link to="/SocialMedia" exact>
            Social Media Management
          </router-link>
        </li>
        <li>
          <router-link to="/Development" exact>Development</router-link>
        </li>
        <li><router-link to="/Pricing" exact>Pricing</router-link></li>
        <li><router-link to="/Resources" exact>Resources</router-link></li>
        <li><router-link to="/About" exact>About</router-link></li>
        <li>
          <router-link to="/ContactUs" exact> Contact Us </router-link>
        </li>
      </ul>
    </nav>
  </v-navigation-drawer>
</template>
<script>
export default {
  data: () => ({
    sfLogo: require("../../assets/images/sf.png"),
    drawer: null,
  }),
  methods: {},
};
</script>

<style scoped lang="scss">
.router-link-active {
  color: #133cd6 !important;
  &.router-link-exact-active {
    color: #133cd6;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: -3px;
      height: 1px;
      background: #133cd6;
    }
  }
}
#header {
  position: sticky;
  z-index: 99;
  top: 0;
  h1 {
    font-family: "marionbold";
  }
  a {
    color: #fff;
  }

  .header-menu {
    text-align: center;
    background: #fff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    ul {
      display: flex;
      justify-content: space-around;
      text-align: center;
      li {
        list-style: none;
        text-transform: uppercase;
        font-weight: 700;
        font-family: "marionbold";
        a {
          color: #000;
          &:hover {
            color: #073e91;
          }
        }
      }
    }
  }
}
.mobile-menu {
  ul {
    li {
      border-bottom: 1px solid #ededed;
      a {
        display: block;
        padding: 10px;
        color: #073e91;
      }
    }
  }
}
</style>
