<template>
  <v-divider></v-divider>
  <v-footer color="grey-lighten-3">
    <v-sheet max-width="400" class="mx-auto text-center" color="transparent">
      {{ new Date().getFullYear() }} —
      <small>
        <a class="text-primary" href="/PrivacyPolicy">
          <strong>Privacy Policy</strong></a
        ></small
      >
    </v-sheet>
  </v-footer>
</template>
<script>
export default {
  data: () => ({
    sfLogo: require("../../assets/images/sf.png"),
    drawer: null,
  }),
  methods: {},
};
</script>
