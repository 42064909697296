import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import ContactUs from "../views/ContactUs.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import DevelopmentPage from "../views/DevelopmentPage.vue";
import SocialMediaPage from "../views/SocialMediaPage.vue";
import PricingPage from "../views/PricingPage.vue";
import AboutUs from "../views/AboutUs.vue";
import ResourcesPage from "../views/ResourcesPage.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/ContactUs",
    name: "ContactUs",
    component: ContactUs,
  },
  {
    path: "/Development",
    name: "DevelopmentPage",
    component: DevelopmentPage,
  },
  {
    path: "/SocialMedia",
    name: "SocialMediaPage",
    component: SocialMediaPage,
  },
  {
    path: "/Pricing",
    name: "PricingPage",
    component: PricingPage,
  },
  {
    path: "/Resources",
    name: "ResourcesPage",
    component: ResourcesPage,
  },
  {
    path: "/About",
    name: "AboutUs",
    component: AboutUs,
  },
  {
    path: "/PrivacyPolicy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
