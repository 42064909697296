<template>
  <!--Header Component-->
  <HeaderComponent />
  <!--Banner Image-->
  <v-parallax :src="bannerImage" class="home-banner" />
  <v-container>
    <h1 class="text-h4 text-brightBlue text-center mb-6">
      Social Media Management
    </h1>
    <v-sheet class="ma-5 mb-10 pa-5 text-center">
      <h2 class="text-h5 mb-5">
        Are your social media efforts falling short of expectations?
      </h2>
      <v-sheet max-width="500" class="mx-auto mb-10">
        <p>
          Now, more than ever, social media is a "pay-to-play" environment
          requiring a cohesive game plan, 100 percent commitment, and a well
          defined budget. On top of organize outreach, getting content in front
          front of your target audience can be challenging without shelling out
          out advertising money, whether for an ongoing campaign or a once-off
          once-off sponsored post.
        </p>
      </v-sheet>
      <h3 class="text-h5 mb-5">Let us be your social manager!</h3>
      <v-sheet>
        <v-row>
          <v-col>
            <v-icon size="130" icon="mdi:mdi-calendar-month" />
            <h3>Consistent Delivery</h3>
            <h4>across all major social media networks</h4>
          </v-col>
          <v-col>
            <v-icon size="130" icon="mdi:mdi-rocket-launch" />
            <h3>Exciting Content</h3>
            <h4>that engages your audience</h4>
          </v-col>
          <v-col>
            <v-icon size="130" icon="mdi:mdi-finance" />
            <h3>Measurable Results</h3>
            <h4>turning followers into customers</h4>
          </v-col>
        </v-row>
      </v-sheet>
    </v-sheet>
  </v-container>
  <v-parallax :src="middleImage" class="home-banner" height="500">
    <v-container>
      <v-row>
        <v-col>
          <div class="mt-4 mb-8">
            <h1 class="text-md-h3 text-h5 text-white">Build a Following</h1>
            <p class="text-grey-lighten-4">
              Want more followers? We've got you! At Social Forge, we use smart
              strategies to help you grow your social crowd. We'll connect you
              with with the right people and share awesome content that keeps
              them for more.
            </p>
          </div>
          <div class="mb-8">
            <h1 class="text-md-h3 text-h5 text-white">Engage Your Audience</h1>
            <p class="text-grey-lighten-4">
              Let's get your audience talking! Our team at Social Forge is all
              creating content that sparks real conversations. We'll keep your
              followers engaged, so you can build a community that feels like
              home.
            </p>
          </div>
          <div>
            <h1 class="text-md-h3 text-h5 text-white">Deliver Results</h1>
            <p class="text-grey-lighten-4">
              Results matter, right? We hear you! At Social Forge, we're on a
              mission to make your social media efforts pay off. We track all
              important numbers and make sure you're getting the ROI you fluff,
              fluff, just real results.
            </p>
          </div>
        </v-col>
        <v-col></v-col>
      </v-row>
    </v-container>
  </v-parallax>
  <v-container>
    <social-pricing-tiles />
    <div class="d-flex align-center justify-space-between">
      <h1>Let's talk about optimizing your social media!</h1>
      <v-btn color="primary" class="text-capitalize">
        <a class="text-white" href="/ContactUs">
          Schedule a Consultation Today
        </a>
      </v-btn>
    </div>
  </v-container>
  <v-parallax :src="bottomImage" class="home-banner" height="500" />
  <!--Footer Component -->
  <FooterComponent />
</template>
<script>
import { defineComponent } from "vue";
// Components
import HeaderComponent from "../components/layout/HeaderComponent.vue";
import FooterComponent from "../components/layout/FooterComponent.vue";
import SocialPricingTiles from "@/components/SocialPricingTiles.vue";

export default defineComponent({
  name: "ServicesView",
  components: {
    HeaderComponent,
    FooterComponent,
    SocialPricingTiles,
  },

  data: () => ({
    bannerImage: require("../assets/images/socialmedia/headerimage.png"),
    middleImage: require("../assets/images/socialmedia/middlebannerimage.jpg"),
    bottomImage: require("../assets/images/socialmedia/bottombannerimage.jpg"),
  }),

  methods: {
    async getData() {
      // errors.value = {};
      try {
        // const result = await api.home.getData({ });
        // add data to lists and render
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
  },

  computed: {},
});
</script>
