<template>
  <!--Header Component-->
  <HeaderComponent />
  <v-container class="mt-10">
    <h1 class="text-h4 text-center text-brightBlue mb-10">
      Why Social Forge Custom Software Development?
    </h1>
    <v-row class="mb-10">
      <v-col cols="12" md="8">
        <h3 class="mb-5">Quality-centric software development Services.</h3>
        <h3 class="mb-5">
          Get savings of up to half compared to your local costs.
        </h3>
        <h3 class="mb-5">Flexible Working Environments.</h3>
        <h3 class="mb-5">Trusted.</h3>
        <h3 class="mb-5">Build your own team of Developers.</h3>
        <h3 class="mb-5">Cultured Compatibility and Support.</h3>
        <h3 class="mb-5">Free Project Management & Single Point of Contact.</h3>
        <h3 class="mb-5">Suitable Time Zone Models.</h3>
        <h3 class="mb-5">Top % of Software Developers Team.</h3>
        <h3 class="mb-5">Secure and stable infrastructure.</h3>
        <h3 class="mb-5">Cost-effective Development Process.</h3>
      </v-col>
      <v-col cols="12" md="4">
        <v-img :src="resOne" alt="resources image"></v-img>
      </v-col>
    </v-row>
    <h2 class="text-h4 text-center text-brightBlue mb-6">
      Embrace opportunities and solve challenges at half the cost!
    </h2>
    <v-sheet max-width="800" class="mx-auto text-center">
      <h3 class="mb-6">
        We assemble from the top % of developers from multiple countries who
        have the expertise to get the job done quickly and done right!
      </h3>
      <v-img class="mb-6" :src="resTwo" alt="resources image"></v-img>
      <h2 class="text-h4 text-center text-brightBlue mb-6">
        100+ skills available
      </h2>
      <h3 class="mb-6">
        Hire React, Node, Python, Angular, Swift, React Native, Android, Java,
        Rails, Golang, DevOps, ML, Data Engineers, and more.
      </h3>
      <h2 class="text-h4 text-center text-brightBlue mb-6">Zero Risk</h2>
      <h3 class="mb-6">
        If you decide to stop service after your first months team package,
        there is no added cost for cancellation.
      </h3>
      <v-img class="mb-6" :src="resThree" alt="resources image"></v-img>
    </v-sheet>
    <h2 class="text-h4 text-center text-brightBlue mb-10 mt-10">
      Focus on building your product. We take care of the rest:
    </h2>
    <v-row class="mt-10">
      <v-col cols="12" md="6">
        <v-sheet max-width="400" class="mx-auto text-center">
          <v-img
            max-width="200"
            class="mb-6 mx-auto"
            :src="resFour"
            alt="resources image"
          ></v-img>
          <h2 class="text-h5 text-center text-brightBlue mb-5">
            Dedicated Team
          </h2>
          <h4>
            Scale your team up or down based on project demands, access the
            right expertise at the right time, and optimize your IT workforce.
            Achieve optimal resource allocation and streamline your operations.
          </h4>
        </v-sheet>
      </v-col>
      <v-col cols="12" md="6">
        <v-sheet max-width="400" class="mx-auto text-center">
          <v-img
            max-width="200"
            class="mb-6 mx-auto"
            :src="resFive"
            alt="resources image"
          ></v-img>
          <h2 class="text-h5 text-center text-brightBlue mb-5">
            IT Staff Augmentation
          </h2>
          <h4>
            Optimize your IT budget with staff augmentation. Avoid the costs
            associated with hiring and training full-time employees. Pay for the
            expertise you need, when you need it, and maximize your ROI.
          </h4>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
  <!--Footer Component -->
  <FooterComponent />
</template>
<script>
import { defineComponent } from "vue";
// Components
import HeaderComponent from "../components/layout/HeaderComponent.vue";
import FooterComponent from "../components/layout/FooterComponent.vue";

export default defineComponent({
  name: "ResourcesPage",
  components: {
    HeaderComponent,
    FooterComponent,
  },

  data: () => ({
    resOne: require("../assets/images/resources/resource1.jpg"),
    resTwo: require("../assets/images/resources/resource2.webp"),
    resThree: require("../assets/images/resources/resource3.jpg"),
    resFour: require("../assets/images/resources/resource4.png"),
    resFive: require("../assets/images/resources/resourece5.webp"),
  }),

  methods: {
    async getData() {
      // errors.value = {};
      try {
        // const result = await api.home.getData({ });
        // add data to lists and render
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
  },

  computed: {},
});
</script>
