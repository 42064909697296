<template>
  <v-sheet>
    <v-img :src="bannerImage" alt="Trust the process"></v-img>
    <v-sheet class="pa-md-10 pa-5">
      <v-row class="d-flex align-center mb-10">
        <v-col cols="12" sm="5">
          <v-img :src="consultationImage" alt="consultation image"></v-img>
        </v-col>
        <v-col cols="12" sm="7" class="text-center">
          <h2 class="text-h4 text-md-h3 mb-4">Let’s Get Started</h2>
          <strong class="text-primary"
            >Social Forge is convenience personified! In just 48 hours, you can
            onboard a team of experts and get started on your project. We will
            ensure we have a smooth relationship with you and everything is
            discussed and finalized before we begin.</strong
          >
        </v-col>
      </v-row>
      <v-row class="mb-10">
        <v-col cols="12" lg="6">
          <v-row>
            <v-col cols="12" lg="auto" class="flex-grow-0 pr-0">
              <v-img
                class="mx-auto"
                width="120"
                :src="emailIcon"
                alt="goals image"
              ></v-img>
            </v-col>
            <v-col
              xl="10"
              lg="9"
              md="12"
              sm="12"
              xs="12"
              class="flex-grow-1 text-center pl-0"
            >
              <h2 class="mb-2 text-md-h3 text-h5">
                Step 1 <span class="text-primary">Welcome Email</span>
              </h2>
              <h4 class="text-primary">
                After you have contacted us we will send you a welcome email
                that details our next steps
              </h4>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" lg="6">
          <v-row>
            <v-col cols="12" lg="auto" class="flex-grow-0 pr-0">
              <v-img
                class="mx-auto"
                width="120"
                :src="phoneIcon"
                alt="goals image"
              ></v-img>
            </v-col>
            <v-col
              xl="10"
              lg="9"
              md="12"
              sm="12"
              xs="12"
              class="flex-grow-1 text-center pl-0"
            >
              <h2 class="mb-2 text-md-h3 text-h5">
                Step 2 <span class="text-primary">Introduction Call</span>
              </h2>
              <h4 class="text-primary">
                We will meet with you to discuss where you want your company to
                go and what you are looking for
              </h4>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6">
          <v-row>
            <v-col cols="12" lg="auto" class="flex-grow-0 pr-0">
              <v-img
                width="120"
                class="mx-auto"
                :src="proposalIcon"
                alt="goals image"
              ></v-img>
            </v-col>
            <v-col
              xl="10"
              lg="9"
              md="12"
              sm="12"
              xs="12"
              class="flex-grow-1 text-center pl-0"
            >
              <h2 class="mb-2 text-md-h3 text-h5">
                Step 3 <span class="text-primary">The Proposal</span>
              </h2>
              <h4 class="text-primary">
                We will provide you with a proposal of all the services and
                products that we believe will be best fit for you after our
                meeting.
              </h4>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" lg="6">
          <v-row>
            <v-col cols="12" lg="auto" class="flex-grow-0 pr-0">
              <v-img
                class="mx-auto"
                width="120"
                :src="takeOffIocn"
                alt="goals image"
              ></v-img>
            </v-col>
            <v-col
              xl="10"
              lg="9"
              md="12"
              sm="12"
              xs="12"
              class="flex-grow-1 text-center pl-0"
            >
              <h2 class="mb-2 text-md-h3 text-h5 text-center">
                Step 4 <span class="text-primary">Take Off</span>
              </h2>
              <h4 class="text-primary">
                Once you have accepted the proposal we will work with you daily
                to make sure our work is as completed as you expect.
              </h4>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-sheet>
  </v-sheet>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "TrustProcess",
  data: () => ({
    bannerImage: require("../assets/images/TrustProcess.png"),
    consultationImage: require("../assets/images/ConsultationImage.jpg"),
    emailIcon: require("../assets/images/EmailIcon.png"),
    takeOffIocn: require("../assets/images/TakeOffIcon.png"),
    proposalIcon: require("../assets/images/ProposalIcon.png"),
    phoneIcon: require("../assets/images/PhoneIcon.png"),
  }),

  methods: {
    async getData() {
      // errors.value = {};
      try {
        // const result = await api.home.getData({ });
        // add data to lists and render
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
  },

  computed: {},
});
</script>
