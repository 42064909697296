<template>
  <!--Header Component-->
  <HeaderComponent />
  <!--Banner Image-->
  <v-container>
    <v-img :src="bannerImage" alt="Services Banner Image"></v-img>
    <v-sheet class="ma-5 mb-10 pa-5 text-center">
      <h2 class="text-h5 mb-5">
        We deliver scalable Tech Solutions with a dedicated team of experts
      </h2>
      <v-sheet max-width="500" class="mx-auto mb-10">
        <p>
          <strong
            >We are the partner you need to deliver scalable, innovative, and
            competitive results. From fully managed delivery teams to individual
            expert software engineers, we specialize in solid end-to-end
            delivery of tailor-made technology solutions.</strong
          >
        </p>
      </v-sheet>
      <v-img
        max-width="500"
        class="mx-auto mb-3"
        :src="servicesTwo"
        alt="Services second Image"
      ></v-img>
      <v-sheet max-width="500" class="mx-auto mb-10">
        <p>
          <strong
            >Our dedicated development team model is aimed to help businesses up
            up their development initiatives and achieve significant cost
            savings by hiring a reliable team of pre-vetted IT talents. A mature
            tech partner with years in IT, Social Forge assembles tailored
            dedicated teams that efficiently address skill gaps and deliver
            high-quality results with no delays or collaboration issues.
          </strong>
        </p>
      </v-sheet>
      <v-img
        max-width="500"
        class="mx-auto mb-3"
        :src="servicesThree"
        alt="Services 3rd Image"
      ></v-img>
      <v-sheet max-width="500" class="mx-auto mb-10">
        <p>
          <strong
            >We conduct a thorough analysis of your project needs and provide
            requirements to a dedicated team: the number of IT talents, the
            needed tech expertise and soft skills, the seniority level,
            certifications, time zones, and more.
          </strong>
        </p>
      </v-sheet>
      <v-img
        max-width="500"
        class="mx-auto mb-3"
        :src="servicesFour"
        alt="Services 4th Image"
      ></v-img>
      <v-sheet max-width="500" class="mx-auto mb-10">
        <p>
          <strong
            >We conduct CV screening of our experts, pick the most fitting
            candidates, and get back to you with our proposal that includes
            detailed information about the pre-selected team members and our
            approach to collaboration. If you are not satisfied with the
            suggested talents, we adjust the CV screening criteria according to
            your feedback and offer more options to fully meet your needs.
          </strong>
        </p>
      </v-sheet>
      <v-img
        max-width="500"
        class="mx-auto mb-3"
        :src="servicesFive"
        alt="Services 5th Image"
      ></v-img>
      <v-sheet max-width="500" class="mx-auto mb-10">
        <p>
          <strong
            >We assemble the chosen team and go through the final steps to kick
            off the project. Together, we clearly define the roles and
            responsibilities of each team member and outline the deliverables to
            be provided by the dedicated team.
          </strong>
        </p>
      </v-sheet>
    </v-sheet>
  </v-container>
  <!--Footer Component -->
  <FooterComponent />
</template>
<script>
import { defineComponent } from "vue";
// Components
import HeaderComponent from "../components/layout/HeaderComponent.vue";
import FooterComponent from "../components/layout/FooterComponent.vue";

export default defineComponent({
  name: "ServicesView",
  components: {
    HeaderComponent,
    FooterComponent,
  },

  data: () => ({
    bannerImage: require("../assets/images/services/servicesbanner.png"),
    servicesTwo: require("../assets/images/services/services2.png"),
    servicesThree: require("../assets/images/services/service3.png"),
    servicesFour: require("../assets/images/services/service4.webp"),
    servicesFive: require("../assets/images/services/services5.svg"),
  }),

  methods: {
    async getData() {
      // errors.value = {};
      try {
        // const result = await api.home.getData({ });
        // add data to lists and render
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
  },

  computed: {},
});
</script>
