<template>
  <!--Header Component-->
  <HeaderComponent />
  <v-sheet class="mb-10">
    <v-img :src="bannerImage" alt="pricing banner image"></v-img>
    <v-container class="mt-10">
      <h1 class="text-h4 text-brightBlue text-center mb-6">
        Why Hire a Dedicated Software Development Team?
      </h1>
      <v-sheet
        max-width="850"
        class="mx-auto text-center"
        style="font-size: 24px"
      >
        <p class="pl-md-10 pr-md-10 ml-md-10 mr-md-10 mb-6">
          Working with dedicated software development teams offers a number of
          advantages over building a software development team in-house. In
          particular, you get immediate access to a well-crafted development
          process and deep expertise in specific areas, such as fintech,
          hospitality, or healthcare. You can cut straight to the chase and save
          money and nerves.
        </p>
      </v-sheet>
      <v-sheet
        max-width="850"
        class="mx-auto text-center mb-10"
        style="font-size: 24px"
      >
        <p class="pl-md-10 pr-md-10 ml-md-10 mr-md-10 mb-6">
          A dedicated development team is a group of specialists assigned to
          work on a specific project for a specific client. This software
          development model, just like the other type of outsourcing, is very
          popular with businesses: a whopping 60% of companies outsource
          software development.
        </p>
        <v-img :src="priceTwo" alt="pricing image"></v-img>
      </v-sheet>
      <h2 class="text-h4 text-brightBlue text-center mb-6">
        How much does it cost to hire a development team?
      </h2>
      <v-sheet
        max-width="850"
        class="mx-auto text-center mb-10"
        style="font-size: 24px"
      >
        <p class="pl-md-10 pr-md-10 ml-md-10 mr-md-10 mb-6">
          Managing a project's budget requires careful consideration. We
          understand the importance of delivering a high-quality digital
          solution while keeping costs under control.
        </p>
      </v-sheet>
      <PricingTiles />
      <v-img :src="priceThree" alt="pricing image"></v-img>
    </v-container>
  </v-sheet>
  <!--Footer Component -->
  <FooterComponent />
</template>
<script>
import { defineComponent } from "vue";
// Components
import HeaderComponent from "../components/layout/HeaderComponent.vue";
import FooterComponent from "../components/layout/FooterComponent.vue";
import PricingTiles from "../components/PricingTiles.vue";
export default defineComponent({
  name: "PricingPage",
  components: {
    HeaderComponent,
    FooterComponent,
    PricingTiles,
  },

  data: () => ({
    bannerImage: require("../assets/images/pricing/pricing1.jpg"),
    priceTwo: require("../assets/images/pricing/pricing2.png"),
    priceThree: require("../assets/images/pricing/pricing3.jpg"),
  }),

  methods: {
    async getData() {
      // errors.value = {};
      try {
        // const result = await api.home.getData({ });
        // add data to lists and render
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
  },

  computed: {},
});
</script>
