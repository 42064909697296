<template>
  <v-sheet>
    <v-img :src="bannerImage" alt="Trust the process"></v-img>
  </v-sheet>
  <v-container>
    <h2 class="text-h2 text-center mt-7 mb-3">Pricing</h2>
    <p class="text-center mb-10 pb-5">
      <strong>
        We provide highly experienced teams of Front-End, Back-End, QA
        Engineers, Scrum Masters, UI/UX Designers, DevOps, & Business Analysts
      </strong>
    </p>
    <PricingTiles />
    <v-sheet class="text-center mb-5">
      <v-btn color="primary" class="text-capitalize"
        ><a class="text-white" href="/ContactUs"
          >Schedule a consultation today!</a
        ></v-btn
      >
    </v-sheet>
  </v-container>
</template>
<script>
import { defineComponent } from "vue";
import PricingTiles from "../components/PricingTiles.vue";
export default defineComponent({
  components: {
    PricingTiles,
  },
  name: "HomePricing",
  data: () => ({
    bannerImage: require("../assets/images/pricing.jpg"),
  }),

  methods: {
    async getData() {
      // errors.value = {};
      try {
        // const result = await api.home.getData({ });
        // add data to lists and render
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
  },

  computed: {},
});
</script>
