<template>
  <v-container>
    <v-row class="d-flex mb-8 mt-6" no-gutters>
      <v-col cols="12" md="4" class="mb-5 mb-md-0">
        <v-sheet color="black" class="pa-4 pt-7 pb-7 text-center">
          <h3 class="text-h5 mb-2 mt-4">Starter</h3>
          <h4>$295/Month</h4>
        </v-sheet>
        <v-sheet color="grey-lighten-3 pa-6 text-center">
          <p>Profile creation and management for 1 platform</p>
          <p>Month Consultation (30 Minutes)</p>
          <p>4 Posts/Month</p>
          <p>Basic Hashtag Strategy</p>
          <p>Monthly Report</p>
        </v-sheet>
      </v-col>
      <v-col cols="12" md="4" class="mb-5 mb-md-0">
        <v-sheet elevation="2">
          <v-sheet
            color="primary"
            class="pa-5 d-none d-md-block text-center mt-n9"
          ></v-sheet>
          <v-sheet color="primary" class="pa-4 pt-7 pb-7 text-center">
            <h3 class="text-h5 mb-2 mt-3">Growth</h3>
            <h4>$595/Month</h4>
          </v-sheet>
          <v-sheet color="grey-lighten-3 pa-6 text-center">
            <p>Profile creation and management for 3 platforms</p>
            <p>BiWeekly Consultation (30 Minutes)</p>
            <p>Monthly Posting Optimization</p>
            <p>Monthly Content Calendar</p>
            <p>15 Posts/Month</p>
            <p>10 Monthly Post Responses</p>
            <p>10 Monthly Messages Responses</p>
            <p>Basic Hashtag Strategy</p>
            <p>Monthly Report</p>
          </v-sheet>
        </v-sheet>
      </v-col>
      <v-col cols="12" md="4">
        <v-sheet color="black" class="pa-4 pt-7 pb-7 text-center">
          <h3 class="text-h5 mb-2 mt-4">Premium</h3>
          <h4>$995/Month</h4>
        </v-sheet>
        <v-sheet color="grey-lighten-3 pa-6 text-center">
          <p>Profile creation and management for 6 platforms</p>
          <p>Weekly Consultation (30 Minutes)</p>
          <p>Monthly Posting Optimization</p>
          <p>Monthly Content Calendar</p>
          <p>30 Posts/Month</p>
          <p>30 Monthly Post Responses</p>
          <p>20 Monthly Messages Responses</p>
          <p>Basic Hashtag Strategy</p>
          <p>Monthly Report</p>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "HomePricing",
  data: () => ({}),

  methods: {
    async getData() {
      // errors.value = {};
      try {
        // const result = await api.home.getData({ });
        // add data to lists and render
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
  },

  computed: {},
});
</script>
