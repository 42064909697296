<template>
  <v-parallax :src="bannerImage">
    <div class="d-flex flex-column fill-height justify-center">
      <v-container>
        <v-sheet :max-width="700" class="text-center bg-transparent text-white">
          <v-img
            width="140"
            class="mx-auto mb-4"
            :src="sfLogo"
            alt="social forge logo"
          ></v-img>
          <h2 class="text-h4 text-mb-h3">
            Turning ideas into beautiful &amp; intuitive digital experiences
          </h2>
        </v-sheet>
      </v-container>
    </div>
  </v-parallax>
  <v-parallax :src="sfBackground">
    <div class="d-flex flex-column fill-height justify-center">
      <v-container>
        <v-row>
          <v-col cols="12" md="6" lg="5">
            <v-row class="d-flex align-center">
              <v-col cols="12" sm="3" md="5" class="flex-grow-0">
                <v-img :src="imageIdea" alt="goals image"></v-img>
              </v-col>
              <v-col cols="12" sm="5" md="7" class="flex-grow-1 text-center">
                <h2 class="mb-2">Bring Your Idea to Life</h2>
                <h4 class="text-primary">
                  Let our team of experts turn<br />
                  your dreams into a reality
                </h4>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" lg="5" class="offset-md-6">
            <v-row class="d-flex align-center" no-gutters>
              <v-col cols="12" sm="3" md="5" class="flex-grow-0">
                <v-img :src="imageIncrease" alt="goals image"></v-img>
              </v-col>
              <v-col cols="12" sm="5" md="7" class="flex-grow-1 text-center">
                <h2 class="mb-2">Return on Investment</h2>
                <h4 class="text-primary">
                  Watch your hard earned money <br />grow in front of your eyes
                </h4>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" lg="5">
            <v-row class="d-flex align-center" no-gutters>
              <v-col cols="12" sm="3" md="5" class="flex-grow-0">
                <v-img :src="imgLevelUp" alt="goals image"></v-img>
              </v-col>
              <v-col cols="12" sm="5" md="7" class="flex-grow-1 text-center">
                <h2 class="mb-2">Level Up your Team</h2>
                <h4 class="text-primary">
                  Let’s define your innovation strategy and <br />
                  guide your team toward your strategic goals
                </h4>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-parallax>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "CustomerGoals",
  data: () => ({
    bannerImage: require("../assets/images/customerGoals.jpg"),
    sfBackground: require("../assets/images/sfbg1.png"),
    sfLogo: require("../assets/images/sf.png"),
    imageIdea: require("../assets/images/IdeatoLife.png"),
    imageIncrease: require("../assets/images/IncreaseInvestment.png"),
    imgLevelUp: require("../assets/images/LevelUp.png"),
  }),

  methods: {
    async getData() {
      // errors.value = {};
      try {
        // const result = await api.home.getData({ });
        // add data to lists and render
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
  },

  computed: {},
});
</script>
