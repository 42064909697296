<template>
  <!--Header Component-->
  <HeaderComponent />
  <!--Home Banner-->
  <HomeBanner id="home" />
  <!-- Home Services-->
  <HomeServices id="services" />
  <!--Customers Goals -->
  <CustomerGoals />
  <!-- Trust The Process -->
  <TrustProcess id="about" />
  <!-- Pricing -->
  <section id="pricing">
    <HomePricing />
  </section>
  <!--Footer Component -->
  <FooterComponent />
</template>
<script>
import { defineComponent } from "vue";
// Components
import HeaderComponent from "../components/layout/HeaderComponent.vue";
import HomeBanner from "../components/HomeBanner.vue";
import HomeServices from "../components/HomeServices.vue";
import CustomerGoals from "../components/CustomerGoals.vue";
import TrustProcess from "../components/TrustProcess.vue";
import HomePricing from "../components/HomePricing.vue";
import FooterComponent from "../components/layout/FooterComponent.vue";

export default defineComponent({
  name: "HomeView",
  components: {
    HeaderComponent,
    HomeBanner,
    HomeServices,
    CustomerGoals,
    TrustProcess,
    HomePricing,
    FooterComponent,
  },

  data: () => ({}),

  methods: {
    async getData() {
      // errors.value = {};
      try {
        // const result = await api.home.getData({ });
        // add data to lists and render
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
  },

  computed: {},
});
</script>
