<template>
  <!--Header Component-->
  <HeaderComponent />
  <v-sheet
    class="mx-auto pa-5 mb-md-10"
    max-width="1024"
    color="grey-lighten-5"
    :elevation="3"
  >
    <h1 class="text-h3 mb-3">Privacy Policy</h1>
    <p class="mb-3">Last Updated: June 21, 2023</p>
    <p class="mb-3">
      At Social Forge, LLC accessible from https://www.social-forge.com, one of
      our main priorities is the privacy of our visitors. This Privacy Policy
      document contains types of information that is collected and recorded by
      Social Forge, LLC and how we use it.
    </p>
    <p class="mb-3">
      If you have additional questions or require more information about our
      Privacy Policy, do not hesitate to contact us.
    </p>
    <p class="mb-3">
      This Privacy Policy applies only to our online activities and is valid for
      visitors to our website with regards to the information that they shared
      and/or collected at Social Forge, LLC. This policy is not applicable to
      any information collected offline or via channels other than this website.
    </p>
    <v-divider class="mb-6"></v-divider>
    <h2 class="text-h5 mb-3">Consent</h2>
    <p class="mb-3">
      By using our website, you hereby consent to our Privacy Policy and agree
      to its terms.
    </p>
    <p class="mb-3">
      The personal information that you are asked to provide, and the reasons
      why you are asked to provide it, will be made clear to you at the point we
      ask you to provide your personal information.
    </p>
    <p class="mb-3">
      If you contact us directly, we may receive additional information about
      you such as your name, email address, phone number, the contents of the
      message and/or attachments you may send us, and any other information you
      may choose to provide.
    </p>
    <p class="mb-3">
      When you register for an Account, we may ask for your contact information,
      including items such as name, company name, address, email address, and
      telephone number.
    </p>
    <v-divider class="mb-6"></v-divider>
    <h2 class="text-h5 mb-3">How we use your information</h2>
    <p class="mb-3">
      We use the information we collect in various ways, including to:
    </p>
    <ul class="ml-10 mb-3">
      <li>
        Provide, operate, and maintain our website Improve, personalize, and
        expand our website
      </li>
      <li>Understand and analyze how you use our website</li>
      <li>Develop new products, services, features, and functionality</li>
      <li>
        Communicate with you, either directly or through one of our partners,
        including for customer service, to provide you with updates and other
        information relating to the website, and for marketing and promotional
        purposes
      </li>
      <li>Send you emails</li>
      <li>Find and prevent fraud</li>
    </ul>
    <v-divider class="mb-6"></v-divider>
    <h2 class="text-h5 mb-3">Log Files</h2>
    <p class="mb-3">
      Social Forge, LLC follows a standard procedure of using log files. These
      files log visitors when they visit websites. All hosting companies do this
      as part of hosting services’ analytics. The information collected by log
      files include internet protocol (IP) addresses, browser type, Internet
      Service Provider (ISP), date and time stamp, referring/exit pages, and
      possibly the number of clicks. These are not linked to any information
      that is personally identifiable. The purpose of the information is for
      analyzing trends, administering the site, tracking users’ movement on the
      website, and gathering demographic information.
    </p>
    <v-divider class="mb-6"></v-divider>
    <h2 class="text-h5 mb-3">Cookies and Web Beacons</h2>
    <p class="mb-3">
      Like any other website, https://www.social-forge.com uses ‘cookies’. These
      cookies are used to store information including visitors’ preferences, and
      the pages on the website that the visitor accessed or visited. The
      information is used to optimize the users’ experience by customizing our
      web page content based on visitors’ browser type and/or other information.
    </p>
    <v-divider class="mb-6"></v-divider>
    <h2 class="text-h5 mb-3">Advertising Partners Privacy Policies</h2>
    <p class="mb-3">
      You may consult this list to find the Privacy Policy for each of the
      advertising partners of Social Forge, LLC.
    </p>
    <p class="mb-3">
      Third-party ad servers or ad networks use technologies like cookies,
      JavaScript, or Web Beacons that are used in their respective
      advertisements and links that appear on Social Forge, LLC which are sent
      directly to users’ browsers. They automatically receive your IP address
      when this occurs. These technologies are used to measure the effectiveness
      of their advertising campaigns and/or to personalize the advertising
      content that you see on websites that you visit.
    </p>
    <p class="mb-3">
      Note that Social Forge, LLC has no access to or control over these cookies
      that are used by third-party advertisers.
    </p>
    <v-divider class="mb-6"></v-divider>
    <h2 class="text-h5 mb-3">Third Party Privacy Policies</h2>
    <p class="mb-3">
      Social Forge’s Privacy Policy does not apply to other advertisers or
      websites. Thus, we are advising you to consult the respective Privacy
      Policies of these third-party ad servers for more detailed information. It
      may include their practices and instructions about how to opt-out of
      certain options.
    </p>
    <p class="mb-3">
      You can choose to disable cookies through your individual browser options.
      To know more detailed information about cookie management with specific
      web browsers, it can be found at the browsers’ respective websites.
    </p>
    <v-divider class="mb-6"></v-divider>
    <h2 class="text-h5 mb-3">
      CCPA Privacy Rights (Do Not Sell My Personal Information)
    </h2>
    <p class="mb-3">
      Under the CCPA, among other rights, California consumers have the right
      to:
    </p>
    <ul class="ml-10 mb-3">
      <li>
        Request that a business that collects a consumer’s personal data
        disclose the categories and specific pieces of personal data that a
        business has collected about consumers.
      </li>
      <li>
        Request that a business delete any personal data about the consumer that
        a business has collected.
      </li>
      <li>
        Request that a business that sells a consumer’s personal data, not sell
        the consumer’s personal data.
      </li>
      <li>
        If you make a request, we have one month to respond to you. If you would
        like to exercise any of these rights, please contact us.
      </li>
    </ul>
    <v-divider class="mb-6"></v-divider>
    <h2 class="text-h5 mb-3">GDPR Data Protection Rights</h2>
    <p class="mb-3">
      We would like to make sure you are fully aware of all of your data
      protection rights. Every user is entitled to the following:
    </p>
    <ul class="ml-10 mb-3">
      <li>
        The right to access – You have the right to request copies of your
        personal data. We may charge you a small fee for this service.
      </li>
      <li>
        The right to rectification – You have the right to request that we
        correct any information you believe is inaccurate. You also have the
        right to request that we complete the information you believe is
        incomplete.
      </li>
      <li>
        The right to erasure – You have the right to request that we erase your
        personal data, under certain conditions.
      </li>
      <li>
        The right to restrict processing – You have the right to request that we
        restrict the processing of your personal data, under certain conditions.
      </li>
      <li>
        The right to object to processing – You have the right to object to our
        processing of your personal data, under certain conditions.
      </li>
      <li>
        The right to data portability – You have the right to request that we
        transfer the data that we have collected to another organization, or
        directly to you, under certain conditions.
      </li>
      <li>
        If you make a request, we have one month to respond to you. If you would
        like to exercise any of these rights, please contact us.
      </li>
    </ul>
  </v-sheet>
  <!--Footer Component -->
  <FooterComponent />
</template>
<script>
import { defineComponent } from "vue";
import HeaderComponent from "../components/layout/HeaderComponent.vue";
import FooterComponent from "../components/layout/FooterComponent.vue";
export default defineComponent({
  name: "HomeView",
  components: {
    HeaderComponent,
    FooterComponent,
  },

  data: () => ({}),

  methods: {
    async getData() {
      // errors.value = {};
      try {
        // const result = await api.home.getData({ });
        // add data to lists and render
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
  },

  computed: {},
});
</script>
<style scoped>
#privacy-header #header .header-menu {
  display: none !important;
}
</style>
