<template>
  <v-container>
    <v-row class="d-flex mb-8" no-gutters>
      <v-col cols="12" md="4" class="mb-5 mb-md-0">
        <v-sheet color="black" class="pa-4 pt-7 pb-7 text-center">
          <h3 class="text-h5 mb-2 mt-4">Maintenance/Research</h3>
          <h4>10 Hours/Week - $395/Week</h4>
        </v-sheet>
        <v-sheet color="grey-lighten-3 pa-6 text-center">
          Augment your software engineering team with talented technology expert
          who can help from day 1!
        </v-sheet>
      </v-col>
      <v-col cols="12" md="4" class="mb-5 mb-md-0">
        <v-sheet elevation="2">
          <v-sheet
            color="primary"
            class="pa-5 d-none d-md-block text-center mt-n9"
          ></v-sheet>
          <v-sheet color="primary" class="pa-4 pt-7 pb-7 text-center">
            <h3 class="text-h5 mb-2 mt-3">Dedicated Team</h3>
            <h4>120 Hours/Week - $3995/Week</h4>
          </v-sheet>
          <v-sheet color="grey-lighten-3 pa-6 text-center">
            A dedicated team of experts at your disposal with access to
            thousands of resources to reduce your cost and time!
          </v-sheet>
        </v-sheet>
      </v-col>
      <v-col cols="12" md="4">
        <v-sheet color="black" class="pa-4 pt-7 pb-7 text-center">
          <h3 class="text-h5 mb-2 mt-4">Team Builder</h3>
          <h4>40 Hours/Week - $1495/Week</h4>
        </v-sheet>
        <v-sheet color="grey-lighten-3 pa-6 text-center">
          The best resources to staff your projects for the widest and most
          complete set of Functional and Technical Software packages!
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "HomePricing",
  data: () => ({}),

  methods: {
    async getData() {
      // errors.value = {};
      try {
        // const result = await api.home.getData({ });
        // add data to lists and render
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
  },

  computed: {},
});
</script>
