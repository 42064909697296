<template>
  <v-card
    :max-width="450"
    class="pa-10 pt-3 mt-10 mb-10 pb-4 contact-form-holder"
  >
    <p class="text-center pt-2 pb-3 text-primary contactus-top-title">
      <strong class="d-block"
        >Ready to improve &amp; grow <br />
        your company or brand?</strong
      >
      <span>Tell us about your project</span>
    </p>
    <v-form ref="form" @submit.prevent="validate" id="myForm">
      <v-row class="ma-0">
        <v-col class="flex-grow-0 pa-0">
          <label class="contact-form-label" for="name">Name</label>
        </v-col>
        <v-col class="flex-grow-1 pa-0 contact-input-field">
          <v-text-field
            id="name"
            variant="solo"
            v-model="fName"
            :rules="fNameRules"
            name="user_name"
            density="compact"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="ma-0">
        <v-col class="flex-grow-0 pa-0">
          <label class="contact-form-label" for="email" color="primary"
            >Email</label
          >
        </v-col>
        <v-col class="flex-grow-1 pa-0 contact-input-field">
          <v-text-field
            id="email"
            variant="solo"
            v-model="email"
            :rules="emailRules"
            name="user_email"
            density="compact"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="ma-0">
        <v-col class="pa-0">
          <v-textarea
            label="Describe Your Need"
            rows="2"
            row-height="20"
            variant="solo"
            color="primary"
            density="compact"
            name="message"
            auto-grow
            class="contact-input-field"
          ></v-textarea>
        </v-col>
      </v-row>
      <vue-recaptcha
        v-show="showRecaptcha"
        sitekey="6Lf5l9UnAAAAABsFLYQIpH4FqNkcdU2B62pO9qoX"
        size="auto"
        theme="light"
        :loading-timeout="loadingTimeout"
        @verify="recaptchaVerified"
        @expire="recaptchaExpired"
        @fail="recaptchaFailed"
        @error="recaptchaError"
        ref="vueRecaptcha"
        class="d-flex justify-center"
      >
      </vue-recaptcha>
      <div class="text-center mt-3">
        <v-btn type="submit" color="primary">Lets grow</v-btn>
      </div>
    </v-form>
  </v-card>
  <v-dialog v-model="successDialogue" max-width="400">
    <v-card color="success" class="text-center">
      <v-card-text>
        Thank you for contacting us we will get back to you soon...
      </v-card-text>
      <v-card-actions class="text-center">
        <v-btn
          width="120"
          class="mx-auto"
          color="white"
          variant="text"
          @click="successDialogue = false"
          >Close</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="errorDialogue" max-width="400">
    <v-card color="error" class="text-center">
      <v-card-text>
        Something went wrong please try again later...
      </v-card-text>
      <v-card-actions class="text-center">
        <v-btn
          width="120"
          class="mx-auto"
          color="white"
          variant="text"
          @click="errorDialogue = false"
          >Close</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import EmailJs from "@emailjs/browser";
import vueRecaptcha from "vue3-recaptcha2";
import { defineComponent } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default defineComponent({
  name: "HomeContactView",
  components: {
    vueRecaptcha,
  },
  data: () => ({
    fName: "",
    fNameRules: [(v) => !!v || "First Name is required"],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) =>
        /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "E-mail must be valid",
    ],
    successDialogue: false,
    errorDialogue: false,
    showRecaptcha: true,
    loadingTimeout: 3000,
  }),
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      name: { required, $autoDirty: true },
      email: { required, $autoDirty: true },
    };
  },
  methods: {
    async getData() {
      // errors.value = {};
      try {
        // const result = await api.home.getData({ });
        // add data to lists and render
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
    async validate() {
      const { valid } = await this.$refs.form.validate();
      if (valid) this.sendEmail();
    },
    sendEmail() {
      EmailJs.sendForm(
        /** Servive ID */
        "service_98gxf4u",
        /** Template ID */
        "template_typrqjq",
        /** Form ID */
        "#myForm",
        /** Public Key */
        "ElK2yRw08bDixmDYc"
      ).then(
        (result) => {
          this.successDialogue = true;
          console.log("SUCCESS!", result.text);
          this.reset();
        },
        (error) => {
          this.errorDialogue = true;
          console.log("FAILED...", error.text);
          this.reset();
        }
      );
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },

  computed: {},
});
</script>
<style lang="scss" scoped>
.contact-form-holder {
  background: rgba(255, 255, 255, 0.8);
  .contact-form-label {
    line-height: 40px;
    padding: 0 10px;
    background: #073e91;
    color: #fff;
    display: block;
    border: 2px solid #073e91;
    margin: 0 -5px 0 0;
  }
  .contact-form-field {
    .v-field {
      border: 1px solid #000 !important;
    }
  }
  .contactus-top-title {
    font-size: 20px;
    span {
      font-size: 16px;
    }
  }
}
</style>
