<template>
  <v-container class="mt-5 mb-5">
    <h3 class="text-primary mb-10 text-center">
      We provide custom software development and IT outsourcing services.<br />
      Our developers build custom software for individuals, startups, and small
      to medium business.
    </h3>
    <v-row>
      <v-col
        cols="6"
        sm="6"
        md="4"
        v-for="service in servicesList"
        :key="service"
      >
        <v-sheet class="text-center" color="white">
          <v-card-text class="pb-2">
            <v-img
              :max-width="170"
              class="mx-auto"
              :src="service.serviceImage"
              :alt="service.service"
            >
              <v-tooltip :max-width="300" activator="parent" location="end">{{
                service.serviceDetail
              }}</v-tooltip>
            </v-img>
          </v-card-text>
          <strong class="font-weight-bold pt-0">
            <v-tooltip :max-width="300" activator="parent" location="end">{{
              service.serviceDetail
            }}</v-tooltip>
            {{ service.service }}</strong
          >
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { defineComponent } from "vue";
// Components

export default defineComponent({
  name: "ServicesView",

  data: () => ({
    servicesList: [
      {
        service: "Web Development",
        serviceImage: require("../assets/images/WebDevelopment.png"),
        serviceDetail:
          "Let our in house professionals be responsible for the design and construction of your website. We can make sure that your site meets user expectations by ensuring they look good, run smoothly and offer easy access points with no loading issues between pages or error messages",
      },
      {
        service: "Social Media Marketing",
        serviceImage: require("../assets/images/SocialMedia.png"),
        serviceDetail:
          "Our Social Media Marketing Specialists will plan, post and promote your online content to all social media platforms for any business, organization, or group. Our Specialist will share content from other sources, comment on posts, and moderate guest or member posts.",
      },
      {
        service: "SAAS",
        serviceImage: require("../assets/images/SAAS.png"),
        serviceDetail:
          "SAAS stands for Software as a Service and is used by businesses to solve their pain points or problems. Let Social Forge turn your ideas into full products. Get to market sooner than you think. Request a free consultation to discover how we can bring your product to life!",
      },
      {
        service: "Mobile Development",
        serviceImage: require("../assets/images/MobileDevelopment.png"),
        serviceDetail:
          "Custom-built tech solutions for your unique development needs with our effective Mobile App Development Team Structure!",
      },
      {
        service: "Lead Generation",
        serviceImage: require("../assets/images/LeadGeneration.png"),
        serviceDetail:
          "Lead generation is the essential part of a business to boost its sales. We can help manage lead and automate your sales process with a new custom build CRM specifically for your needs!",
      },
      {
        service: "Custom CRM’s ",
        serviceImage: require("../assets/images/CRM.png"),
        serviceDetail:
          "Today’s CRM tools are too expensive & difficult to customize. Not here! Our team will take your needs and find a perfect solution to get your business maximizing its potential!",
      },
    ],
  }),

  methods: {
    async getData() {
      // errors.value = {};
      try {
        // const result = await api.home.getData({ });
        // add data to lists and render
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
  },

  computed: {},
});
</script>
