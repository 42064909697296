<template>
  <v-parallax :src="bannerImage" class="home-banner">
    <div class="d-flex flex-column fill-height justify-center">
      <v-container>
        <HomeContactUs />
      </v-container>
    </div>
  </v-parallax>
</template>
<script>
import { defineComponent } from "vue";
// Components
import HomeContactUs from "./HomeContactUs.vue";

export default defineComponent({
  name: "BannerView",
  components: {
    HomeContactUs,
  },

  data: () => ({
    bannerImage: require("../assets/images/bannerImage.jpg"),
  }),

  methods: {
    async getData() {
      // errors.value = {};
      try {
        // const result = await api.home.getData({ });
        // add data to lists and render
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
  },

  computed: {},
});
</script>
