<template>
  <!--Header Component-->
  <HeaderComponent />
  <!--Banner Image-->
  <v-parallax :src="bannerImage" min-height="300">
    <div class="d-flex flex-column fill-height justify-center">
      <v-sheet
        :max-width="500"
        class="text-center bg-secondary banner-caption text-white ml-6 mr-6 pa-6 pa-md-0"
      >
        <h1 class="text-h4 text-md-h2 mb-3">Contact Us</h1>
        <h2 class="text-h6 text-md-h4 mb-2">
          You have <span class="text-primary">dreams</span>
        </h2>
        <h3 class="text-h6 text-md-h4">We have solutions!</h3>
      </v-sheet>
    </div>
  </v-parallax>
  <!-- Request, Schedule,Chat and contact information section  -->
  <v-container>
    <!-- Contact Us submit schedule and chat with us-->
    <v-row class="mt-5 mb-5">
      <!--Submit a request-->
      <v-col cols="12" md="6" lg="4">
        <v-sheet
          class="pa-3 pl-5 pr-5 d-flex flex-column justify-space-between mr-md-5 ml-md-5"
          color="primary"
          min-height="340"
        >
          <div class="box-icon-holder">
            <v-img
              width="100"
              :src="iconEnvelope"
              alt="submit your request"
            ></v-img>
            <h2 class="pl-4 mb-3">Submit a request</h2>
            <h5 class="pl-4 pr-4">
              Send a request directly to us!<br />
              Typical response time: 1 business day
            </h5>
          </div>
          <v-btn
            class="mx-auto pl-10 pr-10 btn-box text-capitalize mb-5"
            color="secondary"
            v-scroll-to="'#contactus'"
            >Submit</v-btn
          >
        </v-sheet>
      </v-col>
      <!--Sechdule a meeting-->
      <v-col cols="12" md="6" lg="4">
        <v-sheet
          class="pa-3 d-flex flex-column justify-space-between mr-md-5 ml-md-5"
          color="primary"
          min-height="340"
        >
          <div class="box-icon-holder">
            <v-img
              width="100"
              :src="iconCalander"
              alt="submit your request"
            ></v-img>
            <h2 class="pl-4 mb-3">Schedule a meeting</h2>
            <h4 class="pl-4 pr-4">
              Set up a meeting with us!<br />Note Your earliest Convenience
            </h4>
            <p class="ml-4 mt-2 text-small">Eastern Standard Time</p>
          </div>
          <v-btn
            class="mx-auto pl-10 pr-10 btn-box text-capitalize mb-5"
            color="secondary"
            @click="openWidget()"
            >Schedule</v-btn
          >
        </v-sheet>
      </v-col>
      <!--chat with us-->
      <v-col cols="12" md="6" lg="4">
        <v-sheet
          class="pa-3 d-flex flex-column justify-space-between mr-md-5 ml-md-5"
          color="primary"
          min-height="340"
        >
          <div class="box-icon-holder">
            <v-img
              width="100"
              :src="iconChat"
              alt="submit your request"
            ></v-img>
            <h2 class="pl-4 mb-3">Live chat</h2>
            <h4 class="pl-4 pr-4">
              Wiat Time: <span class="text-grey">less then 2 minutes!</span
              ><br />Working hours: 9:00 AM - 7:00PM
            </h4>
          </div>
          <v-btn
            class="mx-auto pl-10 pr-10 btn-box text-capitalize mb-5"
            color="secondary"
            id="open_fc_widget"
            @click="openWidget()"
            >chat with us</v-btn
          >
        </v-sheet>
      </v-col>
    </v-row>
    <!--- Perfer to call or email -->
    <v-sheet class="pt-6 pb-6">
      <h2 class="text-center text-h5 text-md-h4 mb-3">
        Prefer to call or email us? Please see the option below.
      </h2>
      <h4 class="text-center mb-6">
        Dial (810) 624-5018, 9:00 AM - 7:00 PM EST / Email:
        <a href="mailto:support@social-forge.com" class="text-primary"
          >Support@social-forge.com</a
        >
      </h4>
      <v-img
        class="mx-auto"
        max-width="400"
        :src="logoDark"
        alt="social forge logo"
      ></v-img>
    </v-sheet>
  </v-container>
  <!-- Contact us form and lets connect section -->
  <v-sheet max-width="2400" class="mx-auto pl-5 pr-5 mb-10" id="contactus">
    <v-row class="mt-10">
      <v-col cols="12" lg="8">
        <v-sheet class="pl-lg-10">
          <v-sheet
            :elevation="10"
            class="contact-us-form contact-form-holder pa-4 pa-md-8 ml-lg-10"
          >
            <h3 class="text-h5 text-center">
              To learn how Social Forge can transform your business
            </h3>
            <h3 class="text-h5 text-center pb-10">
              Schedule a free consultation today!
            </h3>
            <v-form ref="form" @submit.prevent="validate" id="myForm">
              <v-row>
                <!-- Contact Us Firts Name field-->
                <v-col cols="12" md="6" class="pb-0">
                  <v-text-field
                    class="contact-input-field"
                    v-model="fName"
                    :rules="fNameRules"
                    label="First Name"
                    variant="solo"
                    required
                    name="user_name"
                  ></v-text-field>
                </v-col>
                <!-- Contact Us Last Name field-->
                <v-col cols="12" md="6" class="pb-0">
                  <v-text-field
                    class="contact-input-field"
                    v-model="lName"
                    :rules="lNameRules"
                    label="Last Name"
                    variant="solo"
                    name="user_lastname"
                    required
                  ></v-text-field>
                </v-col>
                <!-- Contact Us Phone Number field-->
                <v-col cols="12" md="6" class="pb-0">
                  <v-text-field
                    class="contact-input-field"
                    v-model="phone"
                    :rules="phoneRules"
                    label="Phone"
                    variant="solo"
                    name="user_phone"
                    required
                  ></v-text-field>
                </v-col>
                <!-- Contact Us Email field-->
                <v-col cols="12" md="6" class="pb-0">
                  <v-text-field
                    class="contact-input-field"
                    v-model="email"
                    :rules="emailRules"
                    label="Email"
                    variant="solo"
                    name="user_email"
                    required
                  ></v-text-field>
                </v-col>
                <!-- Contact Us Company Name field-->
                <v-col cols="12" md="12" class="pb-0">
                  <v-text-field
                    class="contact-input-field"
                    v-model="cName"
                    :rules="companyRules"
                    label="Company Name"
                    variant="solo"
                    name="user_company"
                    required
                  ></v-text-field>
                </v-col>
                <!-- Contact Us Textarea-->
                <v-col cols="12" md="12" class="pb-0">
                  <v-textarea
                    class="contact-input-field"
                    label="What can we help you with?"
                    variant="solo"
                    v-model="message"
                    name="message"
                    auto-grow
                    :rules="msgRules"
                  ></v-textarea>
                </v-col>
              </v-row>
              <p class="mt-3">
                This site is protected by reCAPTCHA and the Google Privacy
                Policy & Terms of Service apply
              </p>
              <vue-recaptcha
                v-show="showRecaptcha"
                sitekey="6Lf5l9UnAAAAABsFLYQIpH4FqNkcdU2B62pO9qoX"
                size="normal"
                theme="light"
                :loading-timeout="loadingTimeout"
                @verify="recaptchaVerified"
                @expire="recaptchaExpired"
                @fail="recaptchaFailed"
                @error="recaptchaError"
                ref="vueRecaptcha"
              >
              </vue-recaptcha>
              <div class="d-flex mt-4">
                <!-- Contact Us Firts Name field-->
                <v-row>
                  <v-col cols="12" sm="5">
                    <v-btn
                      block
                      size="large"
                      height="45"
                      color="secondary pl-10 pr-10 mr-5 text-capitalize text-h4"
                      type="submit"
                    >
                      Send
                    </v-btn>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-file-input
                      variant="solo"
                      accept="/*"
                      label="Attach File"
                      density="compact"
                      name="attachment"
                    ></v-file-input>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-sheet>
        </v-sheet>
      </v-col>
      <v-col cols="12" lg="4" class="font-b text-center">
        <v-img
          max-width="300"
          class="mx-auto"
          :src="teamImage"
          alt="team image"
        ></v-img>
        <h2 class="text-h4 text-md-h3 mt-4 mb-4 text-center text-primary">
          Let's Connect
        </h2>
        <p class="mb-5">
          We have highly skilled engineers with excellent technical knowledge
          experience in using latest software standards, tools, platforms,
          frameworks and technologies and we invest continuously in training and
          education, to be able to respond to any new technology challenges and
          demands from our clients.
        </p>
        <h4 class="text-uppercase text-primary mb-1">We like partnerships</h4>
        <p class="mb-5">
          We are your reliable partner for managing dedicated teams for software
          software development. This experience is illustrated by a large
          portfolio of successfully delivered projects
        </p>
        <h4 class="text-uppercase text-primary mb-1">
          We mold on our partners needs
        </h4>
        <p class="mb-5">
          We are agile and ready to align based on project specificity, so our
          partners needs are always met with quality and best resources. We know
          both the art of matching people and the science of projects
        </p>
        <v-sheet class="text-center">
          <v-btn
            color="primary"
            class="mr-1 ml-1"
            icon="mdi:mdi-linkedin"
            size="large"
            href="https://www.linkedin.com/company/social-forge/"
          ></v-btn>
          <v-btn
            color="primary"
            class="mr-1 ml-1"
            icon="mdi:mdi-twitter"
            size="large"
            href="https://twitter.com/social_forge"
          ></v-btn>
          <v-btn
            color="primary"
            class="mr-1 ml-1"
            icon="mdi:mdi-instagram"
            size="large"
            href="https://www.instagram.com/social__forge/"
          ></v-btn>
          <v-btn
            color="primary"
            class="mr-1 ml-1"
            icon="mdi:mdi-facebook"
            size="large"
            href="https://www.facebook.com/SocialForgeLLC"
          ></v-btn>
          <v-btn
            color="primary"
            class="mr-1 ml-1"
            icon="mdi:mdi-chat"
            size="large"
            href="https://www.linkedin.com/company/social-forge/"
          ></v-btn>
        </v-sheet>
      </v-col>
    </v-row>
  </v-sheet>
  <!--Footer Component -->
  <FooterComponent />
  <v-dialog v-model="successDialogue" max-width="400">
    <v-card color="success" class="text-center">
      <v-card-text>
        Thank you for contacting us we will get back to you soon...
      </v-card-text>
      <v-card-actions class="text-center">
        <v-btn
          width="120"
          class="mx-auto"
          color="white"
          variant="text"
          @click="successDialogue = false"
          >Close</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="errorDialogue" max-width="400">
    <v-card color="error" class="text-center">
      <v-card-text>
        Something went wrong please try again later...
      </v-card-text>
      <v-card-actions class="text-center">
        <v-btn
          width="120"
          class="mx-auto"
          color="white"
          variant="text"
          @click="errorDialogue = false"
          >Close</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import EmailJs from "@emailjs/browser";
import vueRecaptcha from "vue3-recaptcha2";
import { defineComponent } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import HeaderComponent from "../components/layout/HeaderComponent.vue";
import FooterComponent from "../components/layout/FooterComponent.vue";
export default defineComponent({
  name: "ContactView",
  components: {
    HeaderComponent,
    FooterComponent,
    vueRecaptcha,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data: () => ({
    bannerImage: require("../assets/images/contactus.jpg"),
    iconEnvelope: require("../assets/images/envelope.png"),
    iconCalander: require("../assets/images/calandar.png"),
    iconChat: require("../assets/images/chat.png"),
    logoDark: require("../assets/images/SVGsocialforge1.svg"),
    teamImage: require("../assets/images/team.png"),
    fName: "",
    fNameRules: [
      (v) => !!v || "First Name is required",
      (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
    ],
    lName: "",
    lNameRules: [(v) => !!v || "Last Name is required"],
    phone: "",
    phoneRules: [
      (v) => !!v || "Phone number is required",
      (v) =>
        Number.isInteger(Number(v)) || "The value must be an integer number",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) =>
        /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "E-mail must be valid",
    ],
    cName: "",
    companyRules: [(v) => !!v || "Company Name is required"],
    message: "",
    msgRules: [(v) => !!v || "Message is required"],
    successDialogue: false,
    errorDialogue: false,
    showRecaptcha: true,
    loadingTimeout: 30000, // 30 seconds
  }),
  validations() {
    return {
      name: { required, $autoDirty: true },
      email: { required, $autoDirty: true },
    };
  },
  methods: {
    openWidget() {
      // this method is not needed, the elevio trigger will handle this for you
      window.fcWidget.open();
    },
    async getData() {
      // errors.value = {};
      try {
        // const result = await api.home.getData({ });
        // add data to lists and render
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
    async validate() {
      const { valid } = await this.$refs.form.validate();
      if (valid) this.sendEmail();
    },
    sendEmail() {
      EmailJs.sendForm(
        /** Servive ID */
        "service_98gxf4u",
        /** Template ID */
        "template_typrqjq",
        /** Form ID */
        "#myForm",
        /** Public Key */
        "ElK2yRw08bDixmDYc"
      ).then(
        (result) => {
          this.successDialogue = true;
          console.log("SUCCESS!", result.text);
          this.reset();
        },
        (error) => {
          this.errorDialogue = true;
          console.log("FAILED...", error.text);
          this.reset();
        }
      );
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    recaptchaVerified(response) {
      console.log(response);
    },
    recaptchaExpired() {
      this.$refs.vueRecaptcha.reset();
    },
    recaptchaFailed() {},
    recaptchaError(reason) {
      console.log(reason);
    },
  },

  computed: {},
});
</script>
<style lang="scss" scoped>
.banner-caption {
  @media only screen and (min-width: 992px) {
    background: transparent !important;
  }
}
.box-icon-holder {
  position: relative;
  &::before {
    content: "";
    height: 3px;
    width: 100px;
    background: #fff;
    position: absolute;
    left: 0;
    top: 4px;
  }
}
.btn-box {
  font-family: "marionbold";
  font-size: 1.5em;
  border: 2px solid #fff;
}
.contact-us-form {
  border: 1px solid #073e91;
}
</style>
